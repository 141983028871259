import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Row from '../components/Row';
import { h1 } from '../styles/typography';

const StyledPage = styled.div`
    background: ${props => props.theme.colors.greyLight};
`;

const StyledRow = styled(Row)`
    position: relative;
    overflow: initial;
`;

const Title = styled.h1`
    ${h1};
    padding: 1em;
`;

const Page404 = () => {
    return (
        <StyledPage>
            <Helmet>
                <title>Not found</title>
            </Helmet>
            <StyledRow>
                <Title>Oops. 404!</Title>
            </StyledRow>
        </StyledPage>
    );
};

export default Page404;
